'use client'

import * as React from 'react'
import { useEffect, useRef } from 'react'
import './livePhotoComponent.css'

export default function LivePhoto({ imgInfo, width, height }) {
  const livePhotoRef = useRef(null)

  useEffect(() => {
    const initializeLivePhotosKit = async () => {
      const LivePhotosKit = (await import('livephotoskit'))
      if (imgInfo.url && imgInfo.video_src) {
        if (livePhotoRef.current) {
          LivePhotosKit.augmentElementAsPlayer(livePhotoRef.current, {
            effectType: 'live',
            photoSrc: imgInfo.url,
            videoSrc: imgInfo.video_src,
            showsNativeControls: true,
          });
        } else {
          console.error("livePhotoRef.current is null");
        }
      } else {
        console.error("Invalid imgInfo.url or imgInfo.video_src");
      }
    };
    initializeLivePhotosKit();
  }, [imgInfo, livePhotoRef])

  return (
    <div className="live-photo-container" ref={livePhotoRef} style={{ cursor: 'pointer', width: width || '225.33px', height: height || '280px', objectFit: 'cover', borderRadius: '8px' }}></div>
  )
}