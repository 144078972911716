import React, { useRef } from 'react';
import './FullScreenImageViewer.css';

const FullScreenImageViewer = ({ src, onClose, description }) => {
  const imgRef = useRef(null);

  const handleOverlayClick = () => {
    onClose();
  };

  const handleImageClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div style={styles.overlay} onClick={handleOverlayClick}>
      <div style={styles.container} onClick={(e) => e.stopPropagation()}>
        <div style={styles.topBar}>
          <svg
            onClick={onClose}
            style={styles.closeButton}
            width="44"
            height="44"
            viewBox="0 0 768 768"
            className="PhotoView-Slider__toolbarIcon"
          >
            <path d="M607.5 205.5l-178.5 178.5 178.5 178.5-45 45-178.5-178.5-178.5 178.5-45-45 178.5-178.5-178.5-178.5 45-45 178.5 178.5 178.5-178.5z"></path>
          </svg>
        </div>
        <img
          ref={imgRef}
          src={src}
          alt="Full Screen"
          style={styles.image}
          onClick={handleImageClick}
        />
        <div style={styles.bottomBar}>
          <p style={styles.description}>
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
    transition: 'opacity 0.3s ease',
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  topBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '0 10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeButton: {
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
  },
  bottomBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '10px',
    textAlign: 'left',
    minHeight: '50px',
  },
  description: {
    color: 'white',
    margin: 0,
    padding: '10px',
  },
};

export default FullScreenImageViewer; 