import React from "react"
import "./style.css"

const loading = () => (
  <div className="loading">
    <div>
      <div className="c1"></div>
      <div className="c2"></div>
      <div className="c3"></div>
      <div className="c4"></div>
    </div>
    <span>loading</span>
  </div>
)

export default loading
